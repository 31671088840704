<template>
  <div>
    <Breadcrumbs
      page_title="frequentlyask"
      :items="breadcrumbs"
      :item_no="item_no ? item_no : 0"
      :item_btn_add="false"
      :btn_text="'addnew'"
      @ShowAction="ShowAction"
    />
    <v-card class="mx-3 my-5" style="border-radius: 16px">
      <!-- height:76vh; -->
      <v-container>
        <v-row justify="space-between" class="ma-1">
          <v-col cols="12" lg="3">
            <v-text-field
              outlined
              dense
              style="border-radius: 8px"
              clearable
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('search')"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
          class="faq-table"
          :headers="headers"
          @page-count="pageCount = $event"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :items="items"
          hide-default-footer
          :search="search"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" class="editbtn">
                  <v-icon style="color: #424242">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="d-flex flex-column align-start">
                <v-btn
                  color="#424242"
                  style="font-size: 16px"
                  text
                  class="text-capitalize"
                  @click="prepareReply(item)"
                >
                  <v-icon left>mdi-file-document-edit-outline</v-icon>
                  {{ $t("edit") }}
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  style="font-size: 16px; color: #4fb14e"
                  text
                  @click="View(item)"
                >
                  <v-icon left>mdi-eye-outline</v-icon>
                  {{ $t("View") }}
                </v-btn>
                <v-btn
                  color="error"
                  style="font-size: 16px"
                  text
                  class="text-capitalize"
                  @click="checkFAQDelete(item)"
                >
                  <v-icon left>mdi-trash-can-outline</v-icon>
                  {{ $t("delete") }}
                </v-btn>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
          </v-col>
        </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="9" md="9" lg="10" xl="10" >
              <div class="d-flex text-left pl-1">
                <v-pagination
                circle
                v-model="page"
                class="pagination"
                :length="pageCount"
              ></v-pagination>
              </div>
             
            </v-col>

            <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="2" class="d-flex justify-end">
              <p class="mt-3 pr-4">{{ $t("Show") }}</p>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    width="70"
                    v-bind="attrs"
                    v-on="on"
                    style="border: 1px solid #c0c0c0; opacity: 1"
                    class="text-capitalize mt-1 mr-3"
                    outlined
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in paginationCount"
                    :key="index"
                    @click="itemsPerPage = item.title"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
      </v-container>
    </v-card>

    <!-- FAQ edit dialog -->
    <v-dialog v-model="replydialog" persistent width="1100">
      <v-card class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title
            style="font-size: 25px; color: #363636; height: 84px; padding: 0px"
          >
            <v-icon class="FAQ">mdi-message-processing-outline</v-icon>
            <p style="margin-left: 20px">FAQ</p>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="replydialog = false"
              color="#424242"
              style="border-radius: 8px !important; margin-bottom: 20px"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-circular
            v-if="actionloading"
            indeterminate
            class="loading-circle"
            color="green"
          ></v-progress-circular>
          <v-divider></v-divider>
          <v-card-text>
            <div class="pt-6">
              <v-row wrap align-center mb-4>
                <v-col
                  cols="12"
                  lg="2"
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: #4d4f5c;
                    opacity: 1;
                  "
                  >{{ $t("userid") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ empid }}</v-col>
              </v-row>
              <v-row wrap align-center mb-4>
                <v-col
                  cols="12"
                  lg="2"
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: #4d4f5c;
                    opacity: 1;
                  "
                  >{{ $t("fullnamesm") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ fullName }}</v-col>
              </v-row>
              <v-row align-center mb-4>
                <v-col>
                  <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">
                    {{ $t("title") }}
                  </p>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="titleName"
                    class="rounded-lg"
                  ></v-text-field>

                  <p
                    style="
                      font-size: 16px;
                      color: #4d4f5c;
                      font-weight: 600;
                      margin-top: 32px;
                    "
                  >
                    {{ $t("questionsm") }}
                  </p>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="questionName"
                    class="rounded-lg"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">
                    {{ $t("answer") }}
                    <span style="color: red">*</span>
                  </p>
                  <v-textarea
                    outlined
                    :rules="replyRules"
                    required
                    name="input-7-4"
                    v-model="reply"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row></v-row>
            </div>
          </v-card-text>

          <v-card-actions class="pb-5 px-4">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize mt-4"
              width="100"
              style="font-size: 16px; color: #424242"
              text
              @click="(replydialog = false), $refs.form.reset()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="text-capitalize white--text mt-4 sendbtn"
              width="100"
              color="#A6CC39"
              style="font-size: 16px"
              @click="updateReply()"
              >{{ $t("update") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- end of FAQ edit dialog -->

    <!-- start of Add FAQ dialog -->
    <v-dialog
      v-model="addDialog"
      persistent
      width="1100"
      style="overflow: hidden"
      :show="addDialog"
    >
      <v-card class="pa-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title
            style="font-size: 25px; color: #363636; height: 84px; padding: 0px"
          >
            <v-icon class="FAQ">mdi-message-processing-outline</v-icon>
            <p style="margin-left: 20px">FAQ</p>

            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="addDialog = false"
              color="#424242"
              style="border-radius: 8px !important; margin-bottom: 20px"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="pt-6">
              <v-row wrap align-center mb-4>
                <v-col
                  cols="12"
                  lg="2"
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: #4d4f5c;
                    opacity: 1;
                  "
                  >{{ $t("userid") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ empid }}</v-col>
              </v-row>
              <v-row wrap align-center mb-4>
                <v-col
                  cols="12"
                  lg="2"
                  style="
                    font-size: 16px;
                    font-weight: 600;
                    color: #4d4f5c;
                    opacity: 1;
                  "
                  >{{ $t("fullnamesm") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ fullName }}</v-col>
              </v-row>
              <v-row align-center mb-4>
                <v-col>
                  <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">
                    {{ $t("title") }}
                  </p>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="titleName"
                    class="rounded-lg"
                  ></v-text-field>

                  <p
                    style="
                      font-size: 16px;
                      color: #4d4f5c;
                      font-weight: 600;
                      margin-top: 32px;
                    "
                  >
                    {{ $t("questionsm") }}
                  </p>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="questionName"
                    class="rounded-lg"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <p style="font-size: 16px; color: #4d4f5c; font-weight: 600">
                    {{ $t("answer") }}
                    <span style="color: red">*</span>
                  </p>
                  <v-textarea
                    outlined
                    :rules="replyRules"
                    required
                    name="input-7-4"
                    v-model="reply"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row></v-row>
            </div>
          </v-card-text>

          <v-card-actions class="pb-5 px-4">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize mt-4"
              width="100"
              style="font-size: 16px; color: #424242"
              text
              @click="(addDialog = false), $refs.form.reset()"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="text-capitalize white--text mt-4 sendbtn"
              width="100"
              color="#A6CC39"
              style="font-size: 16px"
              @click="submit()"
              >{{ $t("submit") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- end of Add FAQ dialog -->

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title
          style="font-size: 25px; color: #363636; height: 84px"
          class="justify-start"
        >
          <p>{{ $t("deleteconfirm") }}</p>
        </v-card-title>
        <v-progress-circular
          v-if="actionloading"
          indeterminate
          class="loading-circle"
          color="green"
        ></v-progress-circular>

        <v-card-text>
          <p
            class="mt-5"
            style="font-size: 20px; color: #363636; text-align: left"
          >
            {{ $t("suredelete") }} "Question #{{ editData.no }}" ?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="text-capitalize"
            width="100"
            style="font-size: 16px; color: #424242"
            text
            @click="dialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize"
            width="100"
            style="font-size: 16px"
            color="error"
            @click="deleteData()"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successDialog" width="450">
      <v-card>
        <v-card-title
          style="font-size: 25px; color: #363636; height: 84px"
          class="justify-center"
        >
          <v-icon style="color: #a6cc39; font-size: 25px; margin: 0px 10px 12px"
            >mdi-checkbox-marked-circle</v-icon
          >
          <p>{{ $t("delsuccess") }}</p>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editsuccessDialog" width="450">
      <v-card>
        <v-card-title
          style="font-size: 25px; color: #363636; height: 84px"
          class="justify-center"
        >
          <v-icon style="color: #a6cc39; font-size: 25px; margin: 0px 10px 12px"
            >mdi-checkbox-marked-circle</v-icon
          >
          <p>{{ "Edit success dialog" }}</p>
        </v-card-title>
      </v-card>
    </v-dialog>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
    <v-dialog v-model="viewdialog" persistent width="587">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title style="font-size: 25px; color: #363636; height: 60px">
            <v-icon class="FAQ">mdi-message-processing-outline</v-icon>
            <p style="margin-left: 20px">FAQ</p>

            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="viewdialog = false"
              color="#424242"
              style="border-radius: 8px !important; margin-bottom: 20px"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div style="margin-right: 13px" class="pt-6">
              <v-row wrap align-center mb-4>
                <v-col
                  cols="3"
                  style="font-size: 16px; color: #4d4f5c; opacity: 1"
                  >{{ $t("userid") }}:</v-col
                >
                <!-- User ID: -->
                <v-col style="font-size: 16px">{{ empid }}</v-col>
                <!-- empid -->
              </v-row>
              <v-row align-center mb-4>
                <v-col
                  cols="3"
                  style="font-size: 16px; color: #4d4f5c; opacity: 1"
                  >{{ $t("fullnamesm") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ fullName }}</v-col>
              </v-row>
              <v-row align-center mb-4>
                <v-col
                  cols="3"
                  style="font-size: 16px; color: #4d4f5c; opacity: 1"
                  >{{ $t("title") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ title }}</v-col>
              </v-row>
              <v-row align-center mb-4>
                <v-col
                  cols="3"
                  style="font-size: 16px; color: #4d4f5c; opacity: 1"
                  >{{ $t("questionsm") }}:</v-col
                >
                <v-col style="font-size: 16px">{{ question }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <p style="font-size: 16px; color: #4d4f5c; opacity: 1">
                    {{ $t("answer") }}
                  </p>
                </v-col>
                <v-col style="font-size: 16px">{{ reply }}</v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize white--text mt-4 sendbtn"
              width="100"
              color="#A6CC39"
              style="margin-right: 20px; font-size: 20px"
              @click="(viewdialog = false), $refs.form.reset()"
              >{{ $t("close") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as moment from "moment/moment";
export default {
  data: () => ({
    item_no: 0,
    permissionDialog: false,
    permissionMessage: "",
    breadcrumbs: [
      {
        // image: "house.png"
        sidebar_tilte: "home",
      },
      {
        text: "community",
        href: "/home/community",
      },
      {
        text: "frequentlyask",
      },
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [{ title: 10 }, { title: 20 }, { title: 30 }],
    items: [],
    editData: {},
    dialog: false,
    successDialog: false,
    editsuccessDialog: false,
    message: "",
    search: null,
    loading: true,
    title: "",
    question: "",
    empid: "",
    reply: "",
    fullName: "",
    actionloading: false,
    viewdialog: false,
    replydialog: false,
    titleName: "",
    questionName: "",
    valid: true,
    replyRules: [(v) => !!v || "Reply is required"],
    addDialog: false,
    id: 0,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    ShowAction(v) {
      if (v == "Add") {
        //function that show add dialog....
        this.checkFAQAdd();
      }
    },
    async checkFAQAdd() {
      let self = this;
      let checkPermission = self.check_Permissions("community");
      if (checkPermission) {
        self.addDialog = true;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to add FAQ.";
      }
    },
    async checkFAQDelete(item) {
      let self = this;
      let checkPermission = self.check_Permissions("community");
      if (checkPermission) {
        self.dialog = true;
        self.editData = item;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to delete FAQ.";
      }
    },
    async getData() {
      let self = this;
      self.loading = true;
      let checkPermission = true;
      if (checkPermission) {
        await self.$axios
          .get(
            `${self.web_url}QNA/GetMostAskedQuestions?id=` +
              localStorage.getItem("companyID") +
              `&offset=` +
              new Date().getTimezoneOffset()
          )
          .then(function (res) {
            self.items = res.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              sendDate: moment(v.sendDate).format("DD/MM/YYYY HH:mm"),
            }));
            self.item_no = self.items.length;
          })
          .catch(function (err) {
            alert(err);
          });
      } else {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to view Community FAQ.";
      }
      self.loading = false;
    },
    View(item) {
      this.viewdialog = true;
      this.title = item.title;
      this.question = item.question;
      this.empid = item.userID;
      this.fullName = item.fullName;
      this.reply = item.reply;
    },
    async deleteData() {
      let self = this;
      self.actionloading = true;
      await self.$axios
        .post(`${self.web_url}QNA/RemoveMQuestion`, {
          id: self.editData.id,
        })

        .then(function (res) {
          self.message = res.data.message;
          self.actionloading = false;
          self.successDialog = true;
        })
        .catch(function (err) {
          alert(err);
        });
      this.dialog = false;
      this.getData();
    },
    async prepareReply(item) {
      let self = this;
      let checkPermission = self.check_Permissions("community");
      if (checkPermission) {
        for (const [key, value] of Object.entries(item)) {
          if (key != "activeFlag" && key != "notiFlag" && key != "postDate") {
            switch (key) {
              case "postActiveFlag":
                self.isFrequentQuestion = value;
                break;
              case "userID":
                self.originalUserID = value;
                break;
              case "question":
                break;
              case "sendDate":
                break;
            }
            self[key] = value;
          }
        }
        self.reply = item.reply == null ? "" : item.reply;
        self.replydialog = true;
        self.replyData = item;
        self.titleName = item.title;
        self.questionName = item.question;
        self.fullName = item.fullName;
        self.id = item.id;
      } else {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to edit FAQ.";
      }
    },

    async updateReply() {
      let self = this;
      self.actionloading = true;
      const data = {
        id: this.id,
        title: this.titleName,
        question: this.questionName,
        reply: this.reply,
        updateBy: localStorage.getItem("userName"),
      };
      await self.$axios
        .post(`${this.web_url}QNA/UpdateMostAskedQuestion`, data)
        .then(function (res) {
          if (res.data.status == 0) {
            self.actionloading = false;
            self.replydialog = false;
            self.editsuccessDialog = true;
            self.getData();
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    close() {
      this.dialog = false;
      this.library = {
        title: "",
        question: "",
        reply: "",
      };
      this.$refs.file.value = "";
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "#",
          value: "no",
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("titlenameM"),
          value: "title",
          sortable: true,
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("questionsm"),
          value: "question",
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("employeeId"),
          align: "left",
          value: "userID",
          class: "tableColor",
        },
        {
          text: this.$t("fullnamesm"),
          value: "fullName",
          sortable: true,
          align: "left",
          class: "tableColor",
        },
        {
          text: this.$t("senddate"),
          align: "left",
          value: "sendDate",
          class: "tableColor",
        },
        {
          text: this.$t("action"),
          align: "center",
          value: "actions",
          class: "tableColor",
        },
      ];
    },
  },
};
</script>

<style scoped>
::v-deep .v-label {
  font-size: 20px !important;
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep .faq-table {
  margin-left: 16px;
  margin-right: 16px;
}
::v-deep .faq-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
::v-deep .faq-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .v-dialog {
  border-radius: 16px !important;
  overflow: hidden !important;
}
::v-deep .primary {
  color: #000000 !important;
  background-color: #a6cc39 !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: unset !important;
  }
}
</style>

<style>
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.editbtn {
  width: 40px !important;
  min-width: 40px !important;
  border: 1px solid #f4f4f4;
}
.FAQ {
  top: -7px;
  color: #363636 !important;
  font-size: 25px;
  left: 10px;
}
.sendbtn:hover {
  background: #4fb14e;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
</style>
